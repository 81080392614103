import React from "react";

const Contact = () => (


  <div className="h-[75vh] container mx-auto p-8 my-12 font-montserrat">
    <h2 className="text-4xl font-bold mb-6 my-12 text-center font-header">CONTACT US</h2>
    <form className="space-y-4 py-8 mx-auto max-w-xl">
      <div>
        <label className="block text-sm font-medium">Name</label>
        <input type="text" className="w-full border rounded p-2" placeholder="Your name" />
      </div>
      <div>
        <label className="block text-sm font-medium">Service Requested</label>
        <input type="text" className="w-full border rounded p-2" placeholder="Service" />
      </div>
      <div>
        <label className="block text-sm font-medium">Date and Time</label>
        <input type="datetime-local" className="w-full border rounded p-2" />
      </div>
      <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">Submit</button>
    </form>
  </div>
);

export default Contact;