import React from "react";

const About = () => (
  <div className="h-[75vh] container mx-auto p-8 my-12 font-montserrat">
    <h2 className="text-4xl font-bold mb-6 my-12 text-center font-header">ABOUT US</h2>
    <p className="text-lg leading-10 py-4">
      Jurski Consulting is a local IT Consulting Services company that offers remote and on-site professional and courteous service to our clients. We specialize in Amazon Web Services, DevOps, Frontend Development, and IT Consulting. Our solutions provide full range of options to meet your business and personal needs. We are cost effective and prompt in bringing the best service you come to expect.
    </p>
  </div>
);

export default About;