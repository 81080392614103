import React from "react";

const MyAccount = () => (
  <div className="container mx-auto p-8">
    <h1 className="text-3xl font-bold mb-4">My Account</h1>
    <div className="border p-4 rounded shadow-lg">
      <p><strong>Name:</strong> John Doe</p>
      <p><strong>Address:</strong> 123 Main St, Anytown, USA</p>
      <p><strong>Services:</strong> DevOps</p>
      <p><strong>Status:</strong> Active</p>
    </div>
  </div>
);

export default MyAccount;