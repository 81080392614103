import React, { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Logo from '../assets/jc-logo.png';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <div className="fixed w-full h-[80px] bg-[#008CD3] px-4 top-0 z-50 text-white">
      <div className="container mx-auto flex justify-between items-center h-full">
        {/* Logo */}
        <div>
          <Link to="/">
            <img src={Logo} alt="Jurski Consulting Logo" className="w-24" />
          </Link>
        </div>

        {/* Desktop Menu */}
        <ul className="hidden md:flex space-x-8">
          <li>
            <Link className="text-white no-underline hover:underline underline-offset-8 decoration-2" to="/">
              Home
            </Link>
          </li>
          <li>
            <Link className="text-white no-underline hover:underline underline-offset-8 decoration-2" to="/about">
              About
            </Link>
          </li>
          <li>
            <Link className="text-white no-underline hover:underline underline-offset-8 decoration-2" to="/services">
              Services
            </Link>
          </li>
          <li>
            <Link className="text-white no-underline hover:underline underline-offset-8 decoration-2" to="/contact">
              Contact
            </Link>
          </li>
          <li>
            <a
              className="text-white no-underline hover:underline underline-offset-8 decoration-2"
              href="https://billing.stripe.com/p/login/14k9AH52kdeeg3C7ss"
              target="_blank"
              rel="noopener noreferrer"
            >
              My Account
            </a>
          </li>
        </ul>

        {/* Hamburger Menu */}
        <div onClick={handleClick} className="md:hidden z-10 cursor-pointer text-white">
          {!nav ? <FaBars size={25} /> : <FaTimes size={25} />}
        </div>

        {/* Mobile Menu */}
        <ul
          className={`absolute top-0 left-0 w-full h-screen bg-[#008CD3] flex flex-col justify-center items-center ${
            nav ? 'block' : 'hidden'
          }`}
        >
          <li className="py-6 text-2xl">
            <Link onClick={handleClick} to="/">
              Home
            </Link>
          </li>
          <li className="py-6 text-2xl">
            <Link onClick={handleClick} to="/about">
              About
            </Link>
          </li>
          <li className="py-6 text-2xl">
            <Link onClick={handleClick} to="/services">
              Services
            </Link>
          </li>
          <li className="py-6 text-2xl">
            <Link onClick={handleClick} to="/contact">
              Contact
            </Link>
          </li>
          <li className="py-6 text-2xl">
            <a
              onClick={handleClick}
              href="https://billing.stripe.com/p/login/14k9AH52kdeeg3C7ss"
              target="_blank"
              rel="noopener noreferrer"
            >
              My Account
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;