import React from "react";

const CookiePolicy = () => (
  <div className="container mx-auto font-montserrat">
    <h2 className="text-4xl font-bold mb-6 my-12 mb-12 text-center font-header">COOKIE POLICY</h2>
    <p className="text-lg mb-12">
      (February 20, 2019)
      <br /><br />
      OVERVIEW<br />
      This website is operated by Jurski LLC. Throughout the site, we use browser cookies to provide Services to you. We use cookies to analyse how you use our Services and provide relevant information to you.
      <br /><br />
      HOW WE USE COOKIES<br />
      By browsing our website, you provide information to us related to your device and visits to each page. We use those cookies to gather information about the pages you have visited and analyze them to better understand the website's needs. In addition, we store information about your preferences and settings to help produce better and friendly experience on the website.
      <br /><br />
      Furthermore, we use social media plugins that also store cookies in your browser. Those cookies can follow you on the internet and build a profile of your interactions and your interest.
      <br /><br />
      HOW TO MANAGE COOKIES AND YOUR CHOICES<br />
      Your browser provides useful tools to manage cookies stored on your computer. You are able to remove, restrict, block most of cookies via settings menu in your browser. Please see help menu in your browser for more information.
      <br /><br />
      There are numerous services which help you remove or even stop targeted advertising. If you would like to change those behaviour targeted advertising please visit the Ad Choices Page (Digital Advertising Alliance) or Your Online Choices (European Interactive Digital Advertising Alliance).
      <br /><br />
      CONTACT US<br />
      Questions about the Cookies Policy should be sent to us at support@jurskinyc.com.    
    </p>
  </div>
);

export default CookiePolicy;