import React from "react";
// import { Link } from 'react-router-dom';
// import LogoBlue from '../assets/jc-logo-blue.png';
// import { FontAwesomeIcon } from 'react-fontawesome';

const Footer = () => {

    // <div className="container mx-auto p-8 m-4 font-montserrat">
    //     <p className="text-lg my-[500px] text-center">
    //     <div>
    //       <Link to="/">
    //         <img src={LogoBlue} alt="Jurski Consulting Logo" className="w-40" />
    //       </Link>
    //     </div>
    //     </p>
    // </div>
    const currentYear = new Date().getFullYear();

    return (

        <footer id="bottom-copyright">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center social-links" style={{ margin: "auto -15px 1.5em auto" }}>
                        <a onMouseOver={() => this.style.color='#3b5998'} onMouseOut={() => this.style.color='black'} title="Facebook" aria-label="Facebook Link" target="_blank" rel="noopener noreferrer" href="http://bit.ly/2tjlM9z" style={{color: "black"}}><i className="fa fa-facebook-square" style={{fontSize: "2em"}} aria-hidden="true"></i></a>
                        <a onmouseover="this.style.color='#fb3958'" onmouseout="this.style.color='black'" title="Instagram" aria-label="Instagram Link" target="_blank" rel="noopener noreferrer" href="http://bit.ly/2urObdr" style={{color: "black"}}><i className="fa fa-instagram" style={{fontSize: "2em"}} aria-hidden="true"></i></a>
                        <a onmouseover="this.style.color='#fffc00'" onmouseout="this.style.color='black'" title="Snapchat" aria-label="Snapchat Link" target="_blank" rel="noopener noreferrer" href="http://bit.ly/2tjkkDU" style={{color: "black"}}><i className="fa fa-snapchat-ghost" style={{fontSize: "2em"}} aria-hidden="true"></i></a>
                        <a onmouseover="this.style.color='#55acee'" onmouseout="this.style.color='black'" title="Twitter" aria-label="Twitter Link" target="_blank" rel="noopener noreferrer" href="http://bit.ly/2t8J7qi"><i className="fa fa-twitter" style={{fontSize: "2em"}} aria-hidden="true"></i></a>
                        <a onmouseover="this.style.color='#582879'" onmouseout="this.style.color='black'" title="GitHub" aria-label="GitHub Link" target="_blank" rel="noopener noreferrer" href="http://bit.ly/2u1y8jx"><i className="fa fa-github" style={{fontSize: "2em"}}aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center font-montserrat my-12">
                        <p>
                            <span style={{fontSize: "14px !important;"}} className="line-break">Copyright &copy; {currentYear}. Jurski LLC. </span><span style={{fontSize: "14px !important;"}} className="line-break">All Rights Reserved.</span>
                            <br/>
                            <span style={{fontSize: "14px !important;"}}>Contact information: </span><span className="line-break"><a style={{fontSize: "14px !important;"}} href="mailto:support@jurskinyc.com" itemprop="url">support@jurskinyc.com</a></span>
                            <br/><br/>
                            <span style={{fontSize: "14px !important;"}}><a href="/terms-and-conditions#section15" target="_self" rel="noopener noreferrer">Work Authorization</a> | <a href="/terms-and-conditions" target="_self" rel="noopener noreferrer" itemprop="url">Terms &amp; Conditions</a> | <a href="/privacy-policy" target="_self" rel="noopener noreferrer" itemprop="url">Privacy Policy</a> <span style={{display: "none"}}>| Warranty</span> | <a href="/cookie-policy" target="_self" rel="noopener noreferrer" itemprop="url">Cookie Policy</a></span>
                            <br/><br/>
                            {/* <span style={{fontSize: "14px !important;"}}>Licensed Business with NYC Department of Consumer Affairs</span>
                            <br/>
                            <span style={{fontSize: "14px !important;"}} className="line-break">Electronic and Home Appliance Service Dealer</span> */}
                            <br/>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;