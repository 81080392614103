import React from "react";

const PrivacyPolicy = () => (
  <div className="container mx-auto p-8 my-12 font-montserrat">
    <h2 className="text-4xl font-bold mb-6 my-12 text-center font-header">PRIVACY POLICY</h2>
    <p className="text-lg">
        (February 20, 2019)
        <br /><br />
        SECTION 1 - GENERAL<br />
        Your privacy is important to us. This Privacy Policy describes the types of information we may collect about you, how we use the information, how we may share the information, the safeguards of the information and to protect your privacy;
        <br /><br />
        This Privacy Policy (“Privacy Policy”) apply when you visit the Jurski Consulting website (www.jurskinyc.com) and any products, services or mobile applications (collectively, the “Site”). By using this Site, you are accepting the terms of this Privacy Policy and you are consenting to our collection, use, sharing and protection of your information as described in this Privacy Policy.
        <br /><br />
        As used throughout this Privacy Policy, the terms (1) “Jurski LLC”, “Jurski Consulting”, “Jurski NYC”, “we”, “us” or “our” means JURSKI LLC and its affiliates, agents; (2) “you” or “your” means any user of the Site; (3) "Service” means the online Profile Accounts (an “Account”) and Computer Repair and IT Related Services, and any other products or services provided through the Site; and (4) “includes” or “including” means “including but not limited to” or “includes but is not limited to.”
        <br /><br />
        We may change this Privacy Policy from time-to-time. If we make changes to this statement, we will update the date at the top of this page. Any changes to this Privacy Policy will become effective when posted unless indicated otherwise. Your continued use of the Site following the posting of any changes will mean that you accept those changes.
        <br /><br />
        We may make links to websites or applications that are owned by third parties ("third-party websites") available on the Site. Information collected by providers of such third-party websites is governed by their privacy policies, which we encourage you to review. These third-party websites operate independently from us and are not subject to this Privacy Policy or any of our privacy policies.
        <br /><br />
        SECTION 2 - HOW WE COLLECT INFORMATION ABOUT YOU<br />
        The personal information we collect about you comes primarily from the information that you provide to us during the course of your business relationship with us. We may also collect information about your interactions and experiences with us relating to your use of the Site and/or the Services. In addition, depending on the Services that we provide to you, we may obtain additional information about you from third parties (for example, we may obtain your demographic information maintained by third parties).
        <br /><br />
        When you interact with the Site on a social media platform, we may collect the personal information that you make available to us relating to that platform, including your account ID or username and other information included in your posts.
        <br /><br />
        Finally, in the provision of Services to you, information may be collected about you indirectly through monitoring or other means (for example, monitoring emails, chats and information collected through your use of the Site). By communicating with us, you acknowledge that your communication may be monitored, or recorded without further notice or warning.
        <br /><br />
        Information That You Provide<br />
        When you visit the Site, request or apply for a Service, establish a customer relationship with us, use a Service or otherwise interact with us, the typical information we may collect about you depends on the Service and may include:
        <br /><br />
        <ul>
            <li>*   Your name, address, phone, email and other contact details and similar information;</li>
            <li>*   Your age;</li>
            <li>*   Personal information that you disclose as part of any Service booking, Account registration, or in other communications with us.</li>
        </ul>
        <br />
        You are not required to supply any of the personal information that we request. However, your failure to do so may result in our being unable to create or maintain an Account or to provide Services to you. You agree to promptly notify us if there are any changes to your personal information by updating your online account in the following manner:
        <br /><br />
        For Accounts, please login to your account and use User Account Management button.
        <br /><br />
        For Service, please email us at support@jurskinyc.com
        <br /><br />
        Automatically Collected Information<br />
        In addition to the information that you provide to us, we collect certain information automatically through your use of the Site or interaction with our advertisements. For example, we may collect data regarding your use of the Site directly or through our service providers using cookies, page tags or similar tools that are set when you visit the Site or when you view an advertisement that we have placed on another site (“Click Data”). Click Data may include computer and connection information such as statistics on your page views, pathways to and from the Site, referral URL, ad data, IP address (which can be used to derive the location of your device), device identifiers, the type of operating system and browser you use, and other device settings. Click Data also may include your browsing history, transaction history, your web log information and other information that may be aggregated and/or de-identified. Click Data helps us suggest Services or service offerings that may be of interest to you. This information may be used by us (or third parties on our behalf) for the purposes described in this Privacy Policy or for other business purposes as permitted by law. For more information about how this Site uses cookies, page tags and similar choices and your rights and choices, please see Cookies and Other Tracking Technologies below.
        <br /><br />
        Location Information<br />
        We may receive information about your location and your mobile device, including a unique identifier for your device. In addition, in some instances, location information can be derived from your IP address or through your wi-fi connection. You can change whether you share specific geo-location information in your device settings; however, some Products or features on the Site may not function properly without this information.
        <br /><br />
        SECTION 3 - OUR USE OF YOUR PERSONAL INFORMATION<br />
        We use your information to provide you with Services and information and to help us personalize and continually improve the Site and Services, including fulfilling requests for information, processing registrations, analyzing and compiling trends and statistics and communicating with you.
        <br /><br />
        We may also use your information to:
        <br /><br />
        <ul>
            <li>*  Administer, operate and manage your relationship and/or Services with us. This may include sharing such information internally as well as disclosing it to third parties, as described in this Privacy Policy;</li>
            <li>*  Contact you through various channels, including mail, telephone, text message, in-app and Site messaging (“chat”), and email, in connection with your Services;</li>
            <li>*  Provide you with customized information and reports, tailored content and marketing messages;</li>
            <li>*  Authenticate your identity, including during the registration process;</li>
            <li>*  Support our internal business operations;</li>
            <li>*  Comply with contractual obligations, relevant industry standards and our policies;</li>
            <li>*  Mitigate fraud, enhance the security of your Services and our online services;</li>
            <li>*  Operate, evaluate and improve our business (including developing new Services and products; improving existing Services and products; performing data analytics; and other internal functions);</li>
            <li>*  Improve Site performance and personalize your experience with the Site;</li>
            <li>*  Notify you about changes to the Site, Services and policies;</li>
            <li>*  Perform analytics concerning your use of our online services, including your responses to our emails and the pages and advertisements you view;</li>
            <li>*  Respond to your requests and otherwise fulfill any purpose for which you provide to us;</li>
            <li>*  For any other purpose that we disclose at the time you provide, or when we collect, your information; and Other purposes permitted by applicable law.</li>
        </ul>
        <br /><br />
        In addition to the above, we may use data that we collect on an aggregate or anonymous basis for various business purposes, where permissible under applicable laws and regulations.
        <br /><br />
        We also may use or combine information that we collect offline or that we receive from third-party sources for many reasons, including to enhance, expand and check the accuracy of our records. Additionally, data collected from a particular browser, device or application may be used with or to recognize you on another browser, device or application linked to you.
        <br /><br />
        We provide you with certain communication choices relating to certain types of communications and information sharing. You may also receive a privacy notice in connection with certain Services that you receive from us describing certain communication choices associated with that Service. You can exercise your communications choices by following any instructions contained in our privacy notices or marketing materials, or:
        <br /><br />
        Please email us support@jurskinyc.com
        <br /><br />
        If your relationship with us ends, we will continue to treat your personal information, to the extent we retain it, as described in this Privacy Policy or as set forth in the privacy notice for the applicable Service.
        <br /><br />
        SECTION 4 - HOW WE SHARE YOUR PERSONAL INFORMATION WITHIN JURSKI LLC<br />
        In order to provide efficient and reliable Services and to improve product and service options available to you, more than one entity within Jurski LLC may be given, or given access to, your personal information.
        <br /><br />
        SECTION 5 - HOW WE SHARE YOUR PERSONAL INFORMATION TO THIRD PARTIES<br />
        We will not sell information that personally identifies you to any third party for their own marketing purposes.
        <br /><br />
        Your personal information may be disclosed to third parties as permitted by, or to comply with, applicable laws and regulations. Examples include responding to a subpoena or similar legal process, protecting against fraud and cooperating with law enforcement or regulatory authorities. We may also disclose your information if we believe it is necessary or appropriate to protect our rights, property or safety or the rights, property or safety of our members, customers or others, or to enforce our contractual rights.
        <br /><br />
        SECTION 6 - HOW YOU CAN ACCESS OR CHANGE YOUR PERSONAL INFORMATION<br />
        You are encouraged to review, correct, or amend your personal information, or to delete that information where it is inaccurate, and you may do so at any time by logging in to your account and clicking the User Account Management button. If you close your Jurski Consulting account, we will mark your account in our database as "Closed," but will keep your account information in our database. This is necessary in order to deter fraud. However, if you close your account, your personally identifiable information will not be used by us for any further purposes, nor sold or shared with third parties, except as necessary to prevent fraud and assist law enforcement, as required by law, or in accordance with this Privacy Policy.
        <br /><br />
        SECTION 7 - COOKIES AND OTHER TRACKING TECHNOLOGIES<br />
        Like many websites, we use tracking technologies such as cookies and similar technologies. We may also use these technologies to, when applicable, record your preferences, track your use of the Site across multiple devices, measure exposure to our online advertisements, monitor traffic, analyze use of the Site, for security purposes, to display information more effectively, to personalize a user’s experience, and to improve the Site and make the Site easier to use.
        <br /><br />
        You have choices to limit some tracking mechanisms that collect information when you use the Site. Many web browsers automatically accept cookies, but you can usually modify your browser’s setting to decline cookies if you prefer. If you choose to decline cookies, certain features of the Site may not function properly or may not remain accessible to you. Note that, if you choose to remove cookies, you may remove opt-out cookies that affect your advertising preferences.
        <br /><br />
        For more information about your choices, see Interest-Based Advertising below.
        <br /><br />
        Cookies<br />
        Cookies are small text files that may be placed on your device when you visit the Site or when you view advertisements that we have placed on other websites. Cookies allow your browser to remember some specific information which the web server can later retrieve and use. When you quit your browser, some cookies are stored in your computer’s memory, while some expire or disappear.
        <br /><br />
        Interest-Based Advertising<br />
        Interest-based advertising refers to collecting information about your online activities over time and across different websites, devices, and other online services to deliver advertisements to users of that device based on their web-viewing behavior. We use interest-based advertising to deliver advertisements and other targeted content to you. To facilitate interest-based advertising, we partner with third-party advertising companies who also use these tracking tools to provide advertisements on the Site or other websites. These third parties may use these technologies to collect information about you when you use the Site and your other online activities. They may collect information about your online activities over time and across different websites and other online services. They may also use persistent identifiers to track your Internet usage across other websites and devices in their networks beyond the Site. They may use this information to provide you with interest-based advertising or other targeted content.
        <br /><br />
        Many of the third-party advertisers that place tracking tools on the Site, are members of the Interactive Advertising Bureau’s Self-Regulatory Program for Online Behavioral Advertising. Some of our ads that are displayed on third-party websites will feature an Ad-Choices icon inside the ad. Clicking on the AdChoices icon inside the ad will provide you with an opportunity to opt out of interest-based advertising by the third parties that participate in the program. In addition, you can learn more about the options available to limit these third parties’ collection and use of your information by visiting our opt-out page and the websites for the Network Advertising Initiative and the Digital Advertising Alliance, as well as the webpages for Facebook’s ad preferences tool and privacy policy.
        <br /><br />
        If you choose to opt-out, a cookie will be placed on your browser or device indicating your decision. This cookie is specific to a particular device and browser, so if you use different browsers or devices, you will need to opt-out on each. In addition, because the opt-out is facilitated via cookies, if you clear your cookies you will need to opt-out again.
        <br /><br />
        Additional Technologies<br />
        We use Google Analytics, a web analytics service provided by Google, Inc. (“Google”), on the Site. Google Analytics uses cookies or other tracking technologies to help us analyze how users interact with and use the Site, compile reports on the Site’s activity and provide other services related to Site activity and usage. The technologies used by Google may collect information such as your IP address, time of visit, whether you are a return visitor and any referring website. The Site does not use Google Analytics to gather information that personally identifies you. The information generated by Google Analytics will be transmitted to and stored by Google and will be subject to Google’s privacy policies. To learn more about Google’s partner services and to learn how to opt out of tracking of analytics by Google, click https://www.google.com/policies/privacy/partners/.
        <br /><br />
        In addition to Google Analytics, we use the Google Maps API to help pre-fill in address information. To learn more about Google’s privacy practices, click http://www.google.com/policies/privacy.
        <br /><br />
        SECTION 8 - SECURITY<br />
        We take the security of your personal information seriously. We seek to limit access to your personal information to authorized members, agents, contractors or vendors. We also maintain electronic and procedural safeguards designed to protect the information against loss, misuse, damage or modification and unauthorized access or disclosure while in our possession.
        <br /><br />
        SECTION 9 - OTHER IMPORTANT INFORMATION<br />
        You must be an individual of at least thirteen (13) years of age and reside in the United States. If you do use the Site outside of the United States, you understand and consent to the transfer of your personal information to, and the collection, processing and storage of your personal information in the United States.        
    </p>
  </div>
);

export default PrivacyPolicy;