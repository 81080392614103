import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import About from "./components/About";
import Contact from "./components/Contact";
import CookiePolicy from "./components/CookiePolicy";
import Footer from "./components/Footer";
import Home from "./components/Home";
import MyAccount from "./components/MyAccount";
import Navbar from "./components/Navbar";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Services from "./components/Services";
import TermsAndConditions from "./components/TermsAndConditions";


const TRACKING_ID = "G-W7QLSSBPV3"; // Replace with your actual Google Analytics Tracking ID

const App = () => {

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ hitType: "pageview", page: "/", title: "Home" });
  }, []);

  return (
    // <div className="flex flex-col items-center box-content bg-home bg-cover bg-center bg-no-repeat">
    //   <div className="w-full h-full max-w-7xl bg-opacity-90">
    //     <Navbar />
    //     <Home />
    //   </div>
    // </div>
    <Router>
      <Navbar />
      <div className="pt-[78px]">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/my-account" element={<MyAccount />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
