import React from "react";

const Services = () => (
  <div className="h-[75vh] container mx-auto p-8 my-12 font-montserrat">
    <h2 className="text-4xl font-bold mb-6 my-12 text-center font-header">SERVICES</h2>
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 py-4">
      {[
        { title: "Frontend Development", icon: "💻", description: "We provide Frontend Development services. We specialize in developing on Drupal and modern web applications." },
        { title: "AWS Cloud", icon: "⚙️", description: "We provide AWS automation services to simplify your cloud infrastructure. We specialize in AWS CLI, Bash, and Terraform." },
        { title: "DevOps", icon: "🛠️", description: "We provide DevOps automation services to simplify your code deployments. We specialize in CI/CD Pipelines with Amazon Web Services." },
        { title: "IT/Tech Support", icon: "🔧", description: "We specialize in troubleshooting and resolving technical issues with your devices." }
      ].map((service, index) => (
        <div key={index} className="border p-8 rounded shadow-sm text-center">
          <div className="text-6xl mb-4">{service.icon}</div>
          <h2 className="text-xl font-bold">{service.title}</h2>
          <p className="text-md font-400 py-8">{service.description}</p>
        </div>
      ))}
    </div>
  </div>
);

export default Services;