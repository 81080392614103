import React, { useEffect, useState } from 'react';
// import { HiArrowNarrowRight } from 'react-icons/hi';
import TextTransition, { presets } from "react-text-transition";

const TEXTS = [
  "Frontend Development",
  "AWS Architecture Automation",
  "DevOps Consulting",
  "IT/Tech Support Consulting"
];

const Home = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() =>
      setIndex(index => index + 1),
      4000 // every 4 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div className="h-[75vh] bg-cover bg-center bg-home bg-no-repeat">
      {/* Container */}
      <div className="container flex justify-between items-center h-full">
        <div className='w-full max-w-[1000px] flex flex-col justify-center h-full'>
          <p className='text-2xl text-[#fff]'>Hello, we are</p>
          <h1 className='text-4xl sm:text-7xl font-bold text-[#fff]'>
            Jurski Consulting
          </h1>
          <h2 className='text-3xl sm:text-3xl font-bold text-[#fff] pj-jobtitle'>
            We provide <span className='text-[#fff]'><TextTransition springConfig={presets.gentle} inline={true} direction={"up"} noOverflow={false}>{TEXTS[index % TEXTS.length]}</TextTransition></span>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Home;
